<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import appConfig from "@/app.config";

export default {
  name: "app",
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    },
  },
  async mounted() {
        // this.animation();
        function initFreshChat() {
            window.fcWidget.init({
                token: "90bec371-4671-4fa9-a539-5dd7c1ccd56c",
                host: "https://wchat.freshchat.com"
            });
        }
        // await this.fetchData();
        function initialize(i, t) { var e; i.getElementById(t) ? initFreshChat() : ((e = i.createElement("script")).id = t, e.async = !0, e.src = "https://wchat.freshchat.com/js/widget.js", e.onload = initFreshChat, i.head.appendChild(e)) } function initiateCall() { initialize(document, "freshchat-js-sdk") } window.addEventListener ? window.addEventListener("load", initiateCall, !1) : window.attachEvent("load", initiateCall, !1);
    }
};
</script>

<style>
.light-blue {
  background: #f9fbff !important;
}
.btn {
  height: 50px;
}

.form-control {
  height: 50px;
}

.authentication-bg {
  background: url("./assets/images/background.png") !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100% !important;
  height: 100vh;
}
.authentication-bg .bg-overlay {
  background: rgba(16, 40, 85, 2.85) !important;
  height: 100vh;
}

.vertical-menu,
.navbar-brand-box {
  background: #102855 !important;
}

body {
  background: #f9fbff !important;
}

.card {
  box-shadow: none !important;
}

.form-group--error {
  border: 1px solid red;
}

.pin-section {
  background: rgba(31, 41, 55, 0.1);
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}

body[data-sidebar="dark"].vertical-collpsed
  .vertical-menu
  #sidebar-menu
  > ul
  > li:hover
  > a {
  background: #fff !important;
  color: #102855 !important;
}

body[data-sidebar="dark"].vertical-collpsed
  .vertical-menu
  #sidebar-menu
  > ul
  > li:hover
  > a > svg > path {
  fill: #102855 !important;
}

body[data-sidebar=dark].vertical-collpsed .vertical-menu #sidebar-menu ul li.mm-active .active {
  color: #102855 !important;
}

@media screen and (max-width: 767px) {
  .mobile-content {
    height: auto !important;
    max-height: auto !important;
    min-height: 0 !important;
  }
}
</style>
