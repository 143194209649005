// import axios from "../../helpers/axios";
import Vue from "vue";
import axios from "axios";
import Vuex from "vuex";
Vue.use(Vuex);
let baseURL = process.env.VUE_APP_API_URL
let AccessKey = process.env.VUE_APP_ACCESS_KEY

export const state = {
  currentUser: null,
  token: null,
  userDetails: null,
  preUserDetails: null,
  otp:null
};

export const mutations = {
  SET_USER_DETAILS(state, userDetails) {
    state.userDetails = userDetails
  },
  SET_PRE_REGISTER_DETAILS(state, preUserDetails) {
    state.preUserDetails = preUserDetails
  },
  SET_OTP_VALUE(state, otp){
    state.otp = otp;
  },
  SET_CURRENT_USER(state, currentUser) {
    state.currentUser = currentUser;
  },

  SET_TOKEN(state, token) {
    state.token = token;
  },
  
  CLEAR_USER_DATA(state) {
    state.token = null;
    state.currentUser = null;
    state.userDetails = null;
    localStorage.clear();
    window.location.href = "/"; 
  },
};

export const getters = {
  // Whether the user is currently logged in.
  loggedIn: (state) => !!state.currentUser,
  getCurrentUser: (state) => state.currentUser,
};

export const actions = {
  // Logs in the current user.
// eslint-disable-next-line no-unused-vars
  login({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseURL}login`, payload, {
          headers: {
            'access-key': AccessKey
          }
        })
        .then((response) => {
          const token = response.data.data;
          commit("SET_TOKEN", token.token);
          commit("SET_USER_DETAILS", payload);
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  // 2FA verification
  loginVerification({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseURL}login2FA`, payload, {
          headers: {
            'access-key': AccessKey
          }
        })
        .then((response) => {
          const token = response.data.data;
          commit("SET_TOKEN", token.token);
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

    // Send Registration otp
    // eslint-disable-next-line no-unused-vars
    postRegistrationOtp({commit}, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${baseURL}continue-register`, payload, {
            headers: {
              'access-key': AccessKey
            }
          })
          .then((response) => {
            // const token = response.data.data;
            commit("SET_OTP_VALUE", payload.token);
            resolve(response);
            // console.log(response)
          })
          .catch((error) => reject(error));
      });
    },

  //   get logged in user
  async getLoggedInUser({ commit }) {
    await axios.get(`${baseURL}user`, {
      headers: {
        'access-key': AccessKey,
        'Authorization': `Bearer ${this.state.auth.token}`
      }
    }).then((response) => {
      const userData = response.data.data;
      commit("SET_CURRENT_USER", userData);
    }).catch(err => {
      console.log(err.response);
    })
  },

  // eslint-disable-next-line no-unused-vars
  getUser({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`${baseURL}user`, {
        headers: {
          'access-key': AccessKey,
          'Authorization': `Bearer ${this.state.auth.token}`
        }
      }).then((response) => resolve(response))
      .catch((error) => reject(error));
    })
    
  },

  //   logout user
  logout({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseURL}user/logout`, {
          headers: {
            'access-key': AccessKey,
            'Authorization': `Bearer ${this.state.auth.token}`
          }
        })
        .then((response) => {
          commit("CLEAR_USER_DATA");
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  // register user
  // eslint-disable-next-line no-unused-vars
  registerUser({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseURL}register`, payload, {
          headers: {
            'access-key': AccessKey,
            'Authorization': `Bearer ${this.state.auth.token}`
          }
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  // verify account number
  // eslint-disable-next-line no-unused-vars
  verifyAccountNumber({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseURL}account-number`, payload, {
          headers: {
            'access-key': AccessKey,
            'Authorization': `Bearer ${this.state.auth.token}`
          }
        })
        .then((response) => {
          resolve(response);
          commit("SET_PRE_REGISTER_DETAILS", response.data.data);
        })
        .catch((error) => reject(error));
    });
  },

  // forget password
  // eslint-disable-next-line no-unused-vars
  forgotPassword({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseURL}forget-password`, payload.payload, {
          headers: {
            'access-key': AccessKey,
            'Authorization': `Bearer ${this.state.auth.token}`
          }
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  // reset password
  // eslint-disable-next-line no-unused-vars
  resetPassword({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseURL}reset-password`, payload.payload, {
          headers: {
            'access-key': AccessKey,
            'Authorization': `Bearer ${this.state.auth.token}`
          }
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  // eslint-disable-next-line no-unused-vars
  fetchAccountDetails({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseURL}continue-register`, payload, {
          headers: {
            'access-key': AccessKey
          }
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

};
