import Vue from "vue";
import axios from "axios";
import Vuex from "vuex";
Vue.use(Vuex);
let baseURL = process.env.VUE_APP_API_URL
let AccessKey = process.env.VUE_APP_ACCESS_KEY

export const state = {
  organisationList: [],
  insuranceDetails: {
    cover_period: null,
    premium: null,
    payment: null,
  },
  step: 1,
};

export const getters = {

};

export const mutations = {
  SAVE_COVER_PERIOD(state, payload) {
    state.insuranceDetails.cover_period = payload;
  },
  SAVE_PREMIUM(state, premium) {
    state.insuranceDetails.premium = premium;
  },
  SAVE_PAYMENT(state, payment) {
    state.insuranceDetails.payment = payment;
  },
  CLEAR_INSURANCE_DETAILS(state) {
    state.insuranceDetails = {};
    state.step = 1;
  },
  INCREASE_STEP(state) {
    state.step++;
  },
  DECREASE_STEP(state) {
    state.step--;
  }
};

export const actions = {
// add organisation
// eslint-disable-next-line no-unused-vars
   addOrganisation({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`{baseURL}user/organisation/create`, payload, {
        headers: {
          'access-key': AccessKey,
          'Authorization': `Bearer ${this.state.auth.token}`
        }
      })
      .then((response) => resolve(response))
      .catch(error => reject(error));
    })
  },

  // add organisation
// eslint-disable-next-line no-unused-vars
updateOrganisation({ commit }, payload) {
  return new Promise((resolve, reject) => {
    axios.patch(`${baseURL}user/organisation/`+payload.payload.organisation_id + '/update', payload.payload.requestBody, {
      headers: {
        'access-key': AccessKey,
        'Authorization': `Bearer ${this.state.auth.token}`
      }
    })
    .then((response) => resolve(response))
    .catch(error => reject(error))
  })
},

  // all insurance
// eslint-disable-next-line no-unused-vars
getAllInsurance({ commit }, payload) {
  return new Promise((resolve, reject) => {
    axios.get(`${baseURL}insurance` + payload.queryString, {
      headers: {
        'access-key': AccessKey,
        'Authorization': `Bearer ${this.state.auth.token}`
      }
    })
    .then((response) => resolve(response))
    .catch(error => reject(error));
  })
},

  // all single insurance
// eslint-disable-next-line no-unused-vars
getSingleInsurance({ commit }, payload) {
  return new Promise((resolve, reject) => {
    axios.post(`${baseURL}insurance/` + payload.payload, {}, {
      headers: {
        'access-key': AccessKey,
        'Authorization': `Bearer ${this.state.auth.token}`
      }
    })
    .then((response) => resolve(response))
    .catch(error => reject(error));
  })
},

  // approval status
// eslint-disable-next-line no-unused-vars
approvalStatus({ commit }, payload) {
  return new Promise((resolve, reject) => {
    axios.post(`${baseURL}insurance/approval/` + payload.payload, {}, {
      headers: {
        'access-key': AccessKey,
        'Authorization': `Bearer ${this.state.auth.token}`
      }
    })
    .then((response) => resolve(response))
    .catch(error => reject(error));
  })
},

  // generate certificate
// eslint-disable-next-line no-unused-vars
generateCertificate({ commit }, payload) {
  return new Promise((resolve, reject) => {
    axios.post(`${baseURL}insurance/certificate/` + payload.payload, {}, {
      headers: {
        'access-key': AccessKey,
        'Authorization': `Bearer ${this.state.auth.token}`
      }
    })
    .then((response) => resolve(response))
    .catch(error => reject(error));
  })
},

  // make claim
// eslint-disable-next-line no-unused-vars
makeClaim({ commit }, payload) {
  return new Promise((resolve, reject) => {
    axios.get(`${baseURL}insurance/make-claim/` + payload.payload, {
      headers: {
        'access-key': AccessKey,
        'Authorization': `Bearer ${this.state.auth.token}`
      }
    })
    .then((response) => resolve(response))
    .catch(error => reject(error));
  })
},

  // check claim status
// eslint-disable-next-line no-unused-vars
checkClaimStatus({ commit }, payload) {
  return new Promise((resolve, reject) => {
    axios.get(`${baseURL}insurance/claim-status/` + payload.payload, {
      headers: {
        'access-key': AccessKey,
        'Authorization': `Bearer ${this.state.auth.token}`
      }
    })
    .then((response) => resolve(response))
    .catch(error => reject(error));
  })
},

  // make payment
// eslint-disable-next-line no-unused-vars
makePayment({ commit }, payload) {
  return new Promise((resolve, reject) => {
    axios.post(`${baseURL}insurance/pay`, payload.payload, {
      headers: {
        'access-key': AccessKey,
        'Authorization': `Bearer ${this.state.auth.token}`,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Access-Control-Allow-Headers': '*'
      }
    })
    .then((response) => resolve(response))
    .catch(error => reject(error));
  })
},

  // hospital list
// eslint-disable-next-line no-unused-vars
getHospital({ commit }, payload) {
  return new Promise((resolve, reject) => {
    axios.post(`${baseURL}hospitals`, payload.payload, {
      headers: {
        'access-key': AccessKey,
        'Authorization': `Bearer ${this.state.auth.token}`
      }
    })
    .then((response) => resolve(response))
    .catch(error => reject(error));
  })
},

storeCoverPriod({commit}, payload) {
  commit("SAVE_COVER_PERIOD", payload)
},

storePremium({commit}, premium) {
  commit("SAVE_PREMIUM", premium)
},

storePayment({commit}, payment) {
  commit("SAVE_PAYMENT", payment)
},

clearInsuranceDetails({commit}) {
  commit("CLEAR_INSURANCE_DETAILS");
},

increaseStep({commit}) {
  commit("INCREASE_STEP");
},

decreaseStep({commit}) {
  commit("DECREASE_STEP");
}

}
