import Vue from "vue";
import axios from "axios";
import Vuex from "vuex";
Vue.use(Vuex);
let baseURL = process.env.VUE_APP_API_URL
let AccessKey = process.env.VUE_APP_ACCESS_KEY

export const state = {
  organisationList: []
};

export const getters = {

};

export const mutations = {

};

export const actions = {
// add organisation
// eslint-disable-next-line no-unused-vars
   addOrganisation({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${baseURL}user/organisation/create`, payload, {
        headers: {
          'access-key': AccessKey,
          'Authorization': `Bearer ${this.state.auth.token}`
        }
      })
      .then((response) => resolve(response))
      .catch(error => reject(error));
    })
  },

  // add organisation
// eslint-disable-next-line no-unused-vars
getAllOrganisations({ commit }) {
  return new Promise((resolve, reject) => {
    axios.get(`${baseURL}user/organisation`, {
      headers: {
        'access-key': AccessKey,
        'Authorization': `Bearer ${this.state.auth.token}`
      }
    })
    .then((response) => resolve(response))
    .catch(error => reject(error));
  })
},

// add organisation
// eslint-disable-next-line no-unused-vars
updateOrganisation({ commit }, payload) {
  return new Promise((resolve, reject) => {
    axios.patch(`${baseURL}user/organisation/`+payload.payload.organisation_id + '/update', payload.payload.requestBody, {
      headers: {
        'access-key': AccessKey,
        'Authorization': `Bearer ${this.state.auth.token}`
      }
    })
    .then((response) => resolve(response))
    .catch(error => reject(error))
  })
}
}
