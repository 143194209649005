import Vue from "vue";
import axios from "axios";
import Vuex from "vuex";
Vue.use(Vuex);
let baseURL = process.env.VUE_APP_API_URL
let AccessKey = process.env.VUE_APP_ACCESS_KEY

export const state = {
  products: null
};

export const getters = {
  getProducts: (state) => state.products,
  getSingleProduct: (state) => (id) => {
    return state.products.find(
      (product) => product.id == id
      );
    },
  };
  
  export const mutations = {
    SET_PRODUCTS(state, products) {
      state.products = products
    },
  };
  
  export const actions = {
    // get all insurance products
    async allProducts({ commit }) {
      await axios.get(`${baseURL}products`, {
        headers: {
          'access-key': AccessKey,
          'Authorization': `Bearer ${this.state.auth.token}`
        }
      }).then((response) => {
        const products = response.data.data
        commit("SET_PRODUCTS", products)
      })
    },
    
    // get premium to pay
    // eslint-disable-next-line no-unused-vars
    getPremiumToPay({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`${baseURL}premium`, payload.payload, {
          headers: {
            'access-key': AccessKey,
            'Authorization': `Bearer ${this.state.auth.token}`
          }
        })
        .then((response) => resolve(response))
        .catch(error => reject(error))
      })
    },

    // buy insurance
    // eslint-disable-next-line no-unused-vars
    buyInsurance({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`${baseURL}buy-insurance/product/` + payload.payload.id, payload.payload.requestBody, {
          headers: {
            'access-key': AccessKey,
            'Authorization': `Bearer ${this.state.auth.token}`
          }
        })
        .then((response) => resolve(response))
        .catch(error => reject(error))
      })
    },

    // get vehicle market value
    // eslint-disable-next-line no-unused-vars
    getMarketValue({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`${baseURL}vehicle-value`, payload, {
          headers: {
            'access-key': AccessKey,
            'Authorization': `Bearer ${this.state.auth.token}`
          }
        })
        .then((response) => resolve(response))
        .catch(error => reject(error))
      })
    },
  }
  