// import axios from "../../helpers/axios";
import Vue from "vue";
import axios from "axios";
import Vuex from "vuex";
Vue.use(Vuex);
let baseURL = process.env.VUE_APP_API_URL
let AccessKey = process.env.VUE_APP_ACCESS_KEY

export const actions = {
  // get stat
  // eslint-disable-next-line no-unused-vars
  getStat({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseURL}user/stat`, {
          headers: {
            'access-key': AccessKey,
            'Authorization': `Bearer ${this.state.auth.token}`
          }
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },
};
