// import store from '@/state/store'

export default [
    {
        path: '/',
        name: 'login',
        component: () => import('../views/auth/login'),
    },{
        path: '/login-verification',
        name: 'login-verification',
        component: () => import('../views/auth/otpVerification'),
    },
    {
        path: '/verify-account',
        name: 'verify-account',
        component: () => import('../views/auth/RegistrationOtp.vue'),
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('../views/auth/register'),
    },
    {
        path: '/continue-register',
        name: 'continue-register',
        component: () => import('../views/auth/completeRegistration'),
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('../views/pages/dashboard/index'),
        meta: { requiresAuth: true },
    },
    {
        path: '/all-products',
        name: 'all-products',
        component: () => import('../views/pages/dashboard/allProducts'),
        meta: { requiresAuth: true },
    },
    {
        path: '/view-products/:id',
        name: 'view-products',
        component: () => import('../views/pages/dashboard/viewProducts'),
        meta: { requiresAuth: true },
    },
    {
        path: '/settings/profile',
        name: 'profile',
        component: () => import('../views/pages/settings'),
        meta: { requiresAuth: true },
    },

    {
        path: '/settings/update-password',
        name: 'password',
        component: () => import('../views/pages/settings/updatePassword'),
        meta: { requiresAuth: true },
    },

    {
        path: '/settings/create-pin',
        name: 'create-pin',
        component: () => import('../views/pages/settings/createPin'),
        meta: { requiresAuth: true },
    },

    {
        path: '/settings/update-pin',
        name: 'update-pin',
        component: () => import('../views/pages/settings/updatePin'),
        meta: { requiresAuth: true },
    },

    {
        path: '/settings/reset-pin',
        name: 'reset-pin',
        component: () => import('../views/pages/settings/resetPin'),
        meta: { requiresAuth: true },
    },

    {
        path: '/forgot-password',
        name: 'Forgot-password',
        component: () => import('../views/auth/forgot-password'),
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        component: () => import('../views/auth/reset-password'),
    },
    {
        path: '/logout',
        name: 'logout',
    },
    
    {
        path: '/organisation',
        name: 'organisation',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/organisation/index')
    },

    {
        path: '/organisation/edit/:id',
        name: 'edit-organisation',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/organisation/editOrganisation'),
    },

    {
        path: '/upload',
        name: 'upload',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/uploads/index')
    },
    {
        path: '/buy-insurance/:id',
        name: 'buy-insurance',
        meta: { authRequired: true },
        component: () => import('../views/pages/buyInsurance/index')
    },

    {
        path: '/all-insurance',
        name: 'all-insurance',
        meta: { authRequired: true },
        component: () => import('../views/pages/insuranceList/index')
    },

    {
        path: '/view-insurance-details/:id',
        name: 'view-insurance-details',
        meta: { authRequired: true },
        component: () => import('../views/pages/insuranceList/viewInsuranceDetails')
    },

    {
        path: '/claims',
        name: 'claims',
        meta: { authRequired: true },
        component: () => import('../views/pages/claims')
    },

    {
        path: '/claims/claim-insurance/:id',
        name: 'claims/claim-insurance',
        meta: { authRequired: true },
        component: () => import('../views/pages/claims/claimInsurance')
    },

    {
        path: '/terms-of-use',
        name: 'terms-of-use',
        component: () => import('../views/pages/Terms')
    },

    {
        path: '*',
        name: '',
        meta: { requiresAuth: true },
        component: () => import('../views/pages/dashboard/index'),
      },
]
