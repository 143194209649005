import Vue from "vue";
import axios from "axios";
import Vuex from "vuex";
Vue.use(Vuex);
let baseURL = process.env.VUE_APP_API_URL
let AccessKey = process.env.VUE_APP_ACCESS_KEY

export const actions = {
  // upload document
  // eslint-disable-next-line no-unused-vars
  uploadDocument({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseURL}upload`, payload, {
          headers: {
            'access-key': AccessKey,
            'Authorization': `Bearer ${this.state.auth.token}`
          }
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  // eslint-disable-next-line no-unused-vars
  checkUpload({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseURL}user/check-upload`, payload.payload, {
          headers: {
            'access-key': AccessKey,
            'Authorization': `Bearer ${this.state.auth.token}`,
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods':'*',
            'Access-Control-Allow-Headers': '*'
          }
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },
};
